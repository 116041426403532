const initIframeSize = () => {
  const iframeWrappers = document.querySelectorAll( '.bs-div--common-form-wrapper' );

  iframeWrappers.forEach( ( iframeWrapper, index ) => {
    const iframe = iframeWrapper.querySelector( 'iframe' );

    const isIframeLoaded = () => {
      return iframe.contentDocument && iframe.contentDocument.readyState === 'complete';
    };

    const reloadIframe = () => {
      iframe.contentWindow.location.reload( true );
    };

    if ( index === 0 ) {
      iframe.addEventListener( 'load', () => {
        if ( !isIframeLoaded() ) {
          reloadIframe();
        }
        iframe.contentWindow.postMessage( 'getIframeHeight', 'https://info.unqork.com' );
      } );
    }

    window.addEventListener( 'message', ( event ) => {
      if ( event.origin === 'https://info.unqork.com' && event.data?.iframeHeight ) {
        const iframeHeight = event.data.iframeHeight + 100;
        iframe.style.height = `${iframeHeight}px`;
        iframeWrapper.style.height = `${iframeHeight}px`;
      }
    } );
  } );
};

document.addEventListener( 'DOMContentLoaded', initIframeSize );
